import React, { useEffect, useState } from 'react'
import callImg from "../../Assets/call.svg";
import LoctionImg from "../../Assets/loction.svg";
import userprofile from "../../Assets/user.svg";
import GrowIcons from "../../Assets/grow.svg";
import Companyimg from "../../Assets/company.svg";
import GlobalImg from "../../Assets/Globe.svg";
import netfliximg from "../../Assets/netfliximage.svg";
import { Link } from "react-router-dom/dist";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SpeedIcon from '@mui/icons-material/Speed';
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils';
import IndustryImage from "../../Assets/office-building.png";
import axios from 'axios';
const ProspectOrganization = ({ organizationData, setLoading }) => {
  // const [organizationData, setorganizationData] = useState()
  // const informationData = () => {
  //   const option = {
  //     method: "GET",
  //     headers: {
  //       "access-control-allow-origin": "*",
  //       "content-type": "application/json",
  //       "X-API-Key": APIUrlToken()
  //     },
  //     url: `${APIUrlThree()}/v1/get_org_description?org_id=${profileData?.org_id}`,
  //   };
  //   axios(option)
  //     .then((e) => {
  //       if (e?.status === 200) {
  //         setorganizationData(e?.data?.data[0]);
  //         setLoading(false)
  //       }
  //     })
  //     .catch(() => {
  //     });
  // };
  // useEffect(() => {
  //   if (profileData?.id) {
  //     informationData()
  //   }
  // }, [profileData])
  return (
    <>
      <section className="exp-main-container">
        <div className=" CompanyProfile-outter-container">
          <div className="CompanyProfile-flex-container">
            <div className="CompanyProfile-flex-outter-container">
              <div className="CompanyProfile-img">
                <img
                  src={IndustryImage}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div>
                <h3 className="Netflix-heading">
                  {organizationData?.name ? organizationData?.name : "-"}
                </h3>
                <div className="CompanyProfile-content">
                  <img
                    className="CompanyProfile-icons"
                    src={GlobalImg}
                    alt=""
                  />
                  {organizationData?.website_url ?
                    <Link
                      to={organizationData?.website_url}
                      target="_blank"
                      className="clickable-link-for-website"
                    >
                      <p>
                        {organizationData?.website_url?.split("www.")?.[1]
                          ? organizationData?.website_url?.split("www.")?.[1]
                          : organizationData?.website_url?.split("http://")?.[1]
                            ? organizationData?.website_url?.split("http://")?.[1] : organizationData?.website_url?.split("https://")?.[1]
                              ? organizationData?.website_url?.split("https://")?.[1] : organizationData?.website_url
                        }
                      </p>
                    </Link> : <p>Not Available</p>}
                </div>
                <div className="CompanyProfile-content">
                  <img className="CompanyProfile-icons" src={callImg} alt="" />
                  <p>
                    {organizationData?.phone_number
                      ? organizationData?.phone_number
                      : "Not Available"}
                  </p>
                </div>
                <div className="CompanyProfile-content">
                  <img
                    className="CompanyProfile-icons"
                    src={LoctionImg}
                    alt=""
                  />
                  {/* <p>{organizationData?.state ? organizationData?.state : organizationData?.country ? organizationData?.country : ""}</p> */}
                  <p>
                    {organizationData?.state && organizationData?.country
                      ? `${organizationData.state} ${organizationData.country}`
                      : ""}
                  </p>
                  {/* <p>{`${organizationData?.street
                    ? organizationData?.street + `,`
                    : organizationData?.street === null
                      ? ""
                      : organizationData?.street
                    } ${organizationData?.location_identifiers
                      ? organizationData?.location_identifiers + `,`
                      : organizationData?.location_identifiers === null
                        ? ""
                        : organizationData?.location_identifiers
                    } ${organizationData?.postalcode
                      ? organizationData?.postalcode + `,`
                      : organizationData?.postalcode === null
                        ? ""
                        : organizationData?.postalcode
                    } ${organizationData?.country
                      ? organizationData?.country
                      : organizationData?.country === null
                        ? ""
                        : organizationData?.country
                    }`}</p> */}
                </div>
              </div>
            </div>
            <div className="CompanyProfile-cont-rightside">
              <div className="CompanyProfile-content">
                {/* <img className="CompanyProfile-icons" src={callImg} alt="" /> */}
                <SpeedIcon className="CompanyProfile-icons ai-score-icon" />
                <p>
                  Company Score -
                  {organizationData?.ai_score !== null && organizationData?.ai_score !== undefined
                    ? organizationData?.ai_score
                    : "NA"}
                </p>
              </div>
              <div className="CompanyProfile-content">
                <img
                  className="CompanyProfile-icons"
                  src={userprofile}
                  alt=""
                />
                <p>
                  Employees-{" "}
                  {organizationData?.num_employees
                    ? organizationData?.num_employees
                    : "Not Available"}
                </p>
              </div>
              <div className="CompanyProfile-content">
                <img className="CompanyProfile-icons" src={GrowIcons} alt="" />
                <p>
                  Annual revenue -{" "}
                  {organizationData?.revenue_range
                    ? organizationData?.revenue_range
                    : "Not Available"}
                </p>
              </div>
              <div className="CompanyProfile-content">
                <img className="CompanyProfile-icons" src={Companyimg} alt="" />
                <p className="industry-mentions">
                  Industry/ Sector - {organizationData?.categories?.split(/(?<=,)|\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ")}
                </p>
              </div>
            </div>
          </div>
          <div className="CompanyProfile-infomation-main">
            <div>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Organization Bio</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="CompanyProfile-infomation">
                    <p className="industry-mentions">
                      {organizationData?.description
                        ? organizationData.description.replace(
                          /(^|\.\s+)([a-z])/g,
                          (match) => match.toUpperCase()
                        )
                        : "Not Available"}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ProspectOrganization