import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import "../Search/Search.css"
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { AI_PROFILE_FORM_TABLE, AI_PROSPECT_PROFILE, COMPANY_PROFILE_SCREEN } from '../../../Utils/Constants';
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import { APIUrlThree, APIUrlToken } from '../../../Utils/Utils';
import axios from 'axios';
export default function HeaderSearch({ headerSearchData, setheaderSearchData, responseData, showSearchdata, setLoading }) {

    const [openAdvanceSearch, setOpenAdvanceSearch] = React.useState(false)
    const [openOrgAdvanceSearch, setOpenOrgAdvanceSearch] = React.useState(false)
    const navigate = useNavigate();

    const [organizationSearch, setOrganizationSearch] = React.useState({
        name: "",
        website: "",
        industry: "",
        linkedInUrl: "",
        country: "",
        address: "",
        city: "",
        state: "",
        limit: 0
    });
    const [prospectSearch, setProspectSearch] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        jobtitle: "",
        organization: "",
        linkedInUrl: "",
        limit: 0
    });
    const [selectedValue, setSelectedValue] = React.useState('Organization')
    const [tableData, setTableData] = React.useState([])

    const [openTableModal, setOpenTableModal] = React.useState(false)

    const handleOrgCancel = () => {
        setOpenAdvanceSearch(false);
        setOpenOrgAdvanceSearch(false);
        setOrganizationSearch({
            name: "",
            website: "",
            industry: "",
            linkedInUrl: "",
            country: "",
            address: "",
            city: "",
            state: "",
            limit: 0
        })
        setProspectSearch({
            firstName: "",
            lastName: "",
            email: "",
            jobtitle: "",
            organization: "",
            linkedInUrl: "",
            limit: 0
        });
    }
    const handelselectdata = (item) => {
        setheaderSearchData(item?.name);
        if (item?.type === "org") {
            navigate(`/companyprofilescreen/${item?.org_id}/0`, { state: { isComponyScreen: window.location.pathname.includes('companyprofilescreen') } });
            // navigate(`/companyprofilescreen`, { state: { data: item, isComponyScreen: window.location.pathname === '/companyprofilescreen' } });
        } else {
            navigate(`/Profile/${item?.id == 0 ? "0" : item?.id}`);
        }
    };
    const emtySearchdata = () => {
        setheaderSearchData("")
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };
    const style2 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80vw",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };

    const handleSearchRecords = () => {
        if (selectedValue === "Organization") {
            handleAdvSearchOrganization()
        } else {
            handleAdvSearchProspect()
        }
    }
    const validateOrg = () => {
        if (!organizationSearch?.name || organizationSearch?.name?.trim() == "") {
            toast.error("Please enter organization name");
            return false
        } else if (!organizationSearch?.website && !organizationSearch?.linkedInUrl) {
            toast.error("Please enter either website or linkedin Url");
            return false
        }
        return true
    }
    const handleAdvSearchOrganization = async () => {
        // if (!validateOrg()) return
        if (!organizationSearch?.name && !organizationSearch?.website && !organizationSearch?.industry && !organizationSearch?.linkedInUrl && !organizationSearch?.country && !organizationSearch?.address && !organizationSearch?.city && !organizationSearch?.state) {
            toast.error("Please enter some info");
            return
        }
        setLoading(true)
        const data = {
            "id": "string",
            "organization_name": organizationSearch?.name,
            "org_website": organizationSearch?.website,
            "org_industry": organizationSearch?.industry,
            "org_linkedIn": organizationSearch?.linkedInUrl,
            "org_country": organizationSearch?.country,
            "org_address": organizationSearch?.address,
            "org_city": organizationSearch?.city,
            "org_state": organizationSearch?.state,
            "limit": 100
        }

        const options = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/advance_search_org`,
        };
        try {
            const response = await axios(options);
            setLoading(false)
            if (response?.data?.data?.length > 0) {
                setTableData(response?.data?.data)
                setOpenAdvanceSearch(false)
                setOpenOrgAdvanceSearch(false);
                setOpenTableModal(true)
            } else {
                toast.error("No records found")
            }

        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message);
        } finally {
        }
    }
    const validateProspects = () => {
        if (!prospectSearch?.firstName || prospectSearch?.firstName?.trim() == "") {
            toast.error("Please enter First Name");
            return false
        } else if (!prospectSearch?.lastName || prospectSearch?.lastName?.trim() == "") {
            toast.error("Please enter Last Name");
            return false
        } else if (!prospectSearch?.email && !prospectSearch?.linkedInUrl) {
            toast.error("Please enter either email or linkedin Url");
            return false
        }
        return true
    }
    const handleAdvSearchProspect = async () => {
        // if (!validateProspects()) return
        if (!prospectSearch?.firstName && !prospectSearch?.lastName && !prospectSearch?.email && !prospectSearch?.jobtitle && !prospectSearch?.organization && !prospectSearch?.linkedInUrl) {
            toast.error("Please enter some info");
            return
        }
        setLoading(true)
        const data = {
            "id": "string",
            "first_name": prospectSearch?.firstName,
            "last_name": prospectSearch?.lastName,
            "email": prospectSearch?.email,
            "job_title": prospectSearch?.jobtitle,
            "organization": prospectSearch?.organization,
            "linkedIn_url": prospectSearch?.linkedInUrl,
            "limit": 100
        }

        const options = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/advance_search_people`,
        };
        try {
            const response = await axios(options);
            setLoading(false)
            if (response?.data?.data?.length > 0) {
                setTableData(response?.data?.data)
                setOpenAdvanceSearch(false)
                setOpenOrgAdvanceSearch(false);
                setOpenTableModal(true)
            } else {
                toast.error("No records found")
            }
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message);
        } finally {
        }
    }

    return (
        <>
            <Paper className='full-searchbar'
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
                <SearchIcon className='searchicon-in-header' />
                <InputBase
                    value={headerSearchData}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        setheaderSearchData(inputValue);
                    }} className='search-inner-text'
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ 'aria-label': 'Search google maps' }}
                    autocomplete="off"
                />
                <div onClick={emtySearchdata} className='cross-icon-prospect-search'>
                    {headerSearchData && (
                        <CloseIcon className='showicons-search' />
                    )}            </div>
                {showSearchdata && (
                    <div className={window.location.pathname === COMPANY_PROFILE_SCREEN
                        || window.location.pathname === AI_PROFILE_FORM_TABLE
                        || window.location.pathname === AI_PROSPECT_PROFILE ? 'NewClassNamepathname' : 'Autocompletedropdown'}>
                            
                        {/* <div className='outterAutocompletedropdown' onClick={() => setOpenAdvanceSearch(true)}>
                            <div className='useralldata red-search'>
                                ADVANCED SEARCH
                            </div>
                            <div className='separatorline'></div>
                        </div> */}
                        {/* {responseData?.length > 0 ? (
                            responseData.sort((a, b) => {
                                const nameA = a?.name?.toUpperCase() || ''; // Convert to uppercase to make sorting case-insensitive
                                const nameB = b?.name?.toUpperCase() || '';
                                return nameA.localeCompare(nameB); // Compare the names alphabetically
                            }).map((item) => (
                                <>
                                {
                                    item?.id !==null ? 
                                <div className='outterAutocompletedropdown' key={item.id}>
                                    <div onClick={() => handelselectdata(item)} className='useralldata'>
                                        {[
                                            item?.name?.split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            )
                                            .join(" "),
                                            item?.state?.split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            )
                                            .join(" "),
                                            item?.country?.split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            )
                                            .join(" "),
                                            item?.website_url,
                                            item?.organization?.split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            )
                                            .join(" "),
                                            item?.seniority?.split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            )
                                            .join(" "),
                                            item?.linked_url
                                        ]
                                            .filter(Boolean)
                                            .join(', ')}
                                    </div>
                                    <div className='separatorline'></div>
                                </div>:
                                  <div className='outterAutocompletedropdown2' key={item.id}>
                                  <div className='useralldata'>
                                      {item?.name}
                                  </div>
                                  <div className='separatorline'></div>
                              </div>
                                }
                                </>
                            ))
                        ) : (
                            <div className='useralldata'>Not Available</div>
                        )} */}
                        {/* {responseData?.length > 0 ? (
                            responseData.map((item, index) => (
                                <>
                                    {item?.id === null ? (
                                        <div className='outterAutocompletedropdown2' key={`heading-${index}`}>
                                            <div className='useralldata'>
                                                <strong>{item?.name}</strong>
                                            </div>
                                            <div className='separatorline'></div>
                                        </div>
                                    ) : (
                                        <div className='outterAutocompletedropdown' key={item.id}>
                                            <div onClick={() => handelselectdata(item)} className='useralldata'>
                                                {[
                                                    item?.name?.split(" ")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.state?.split(" ")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.country?.split(" ")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.website_url,
                                                    item?.organization?.split(" ")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.seniority?.split(" ")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.linked_url,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')}
                                            </div>
                                            <div className='separatorline'></div>
                                        </div>
                                    )}
                                </>
                            ))
                        ) : (
                            <div className='useralldata'>Not Available</div>
                        )} */}

                        {responseData?.length > 0 ? (
                            responseData.map((item, index) => (
                                <>
                                    {item?.id === null ? (
                                        <div className='outterAutocompletedropdown2' key={`heading-${index}`}>
                                            <div className='useralldata'>
                                                <strong>{item?.name}</strong>
                                            </div>
                                            <div className='separatorline'></div>
                                        </div>
                                    ) : (
                                        <div className='outterAutocompletedropdown' key={item.id || `data-${index}`}>
                                            <div onClick={() => handelselectdata(item)} className='useralldata'>
                                                {[
                                                    item?.name?.split(" ")
                                                        .map(
                                                            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.state?.split(" ")
                                                        .map(
                                                            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.country?.split(" ")
                                                        .map(
                                                            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.website_url,
                                                    item?.organization?.split(" ")
                                                        .map(
                                                            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.seniority?.split(" ")
                                                        .map(
                                                            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                        )
                                                        .join(" "),
                                                    item?.linked_url,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')}
                                            </div>
                                            <div className='separatorline'></div>
                                        </div>
                                    )}
                                </>
                            ))
                        ) : (
                            <div className='useralldata'>Not Available</div>
                        )}
                    </div>
                )}
            </Paper>
            <Modal
                open={openAdvanceSearch}
                // onClose={() => { setOpenAdvanceSearch(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="advance-search-modal">
                    <Box sx={style} style={{ overflowY: "auto" }}>
                        <div className="de_pad-adv-search">
                            <h3 className='adv-search-modal'>Advanced Search</h3>
                            <p className='sub-head-adv-search-mdl'>Please choose an Organization or a Prospect to search for.</p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Organization"
                                    name="radio-buttons-group"
                                    onChange={(e) => setSelectedValue(e?.target?.value)}
                                >
                                    <FormControlLabel value="Organization" control={<Radio />} label="Organization" />
                                    <FormControlLabel value="Prospect" control={<Radio />} label="Prospect" />
                                </RadioGroup>
                            </FormControl>
                            <div className="de_hr">
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={() => { handleOrgCancel() }} >Cancel</Button>
                                    <Button className='de_next' onClick={() => { setOpenOrgAdvanceSearch(true) }}>Next</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
            <Modal
                open={openOrgAdvanceSearch}
                // onClose={() => { setOpenOrgAdvanceSearch(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="advance-search-modal">
                    <Box sx={style} style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <div className="de_pad-org-adv-search">
                            <h3 className='adv-search-modal'>{selectedValue === "Organization" ? "Organization" : "Prospect"} - Advanced Search</h3>
                            <p className='sub-head-adv-search-mdl'>Can’t find an {selectedValue === "Organization" ? "Organization" : "Prospect"} you are looking for? Try our Advanced Search to see if we can locate who you are looking for.</p>
                            {selectedValue === "Organization" ?
                                <div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Organization Name <span className='red-search'>*</span>
                                        </label>
                                        <input type="text" className="input-floating-label-adv" value={organizationSearch?.name}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, name: e?.target?.value })} />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Website
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.website}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, website: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            LinkedIn URL
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.linkedInUrl}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, linkedInUrl: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Industry
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.industry}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, industry: e?.target?.value })}
                                        />
                                    </div>

                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Country
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.country}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, country: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Address
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.address}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, address: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            City
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.city}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, city: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            State
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={organizationSearch?.state}
                                            onChange={(e) => setOrganizationSearch({ ...organizationSearch, state: e?.target?.value })}
                                        />
                                    </div>
                                </div> : <div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            First Name<span className='red-search'>*</span>
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.firstName}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, firstName: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Last name<span className='red-search'>*</span>
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.lastName}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, lastName: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Linkedin Url
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.linkedInUrl}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, linkedInUrl: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Email
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.email}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, email: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Organization
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.organization}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, organization: e?.target?.value })}
                                        />
                                    </div>
                                    <div className="inner-main-object-select">
                                        <label htmlFor="" className="label-floating">
                                            Job Title
                                        </label>
                                        <input type="text" className="input-floating-label-adv"
                                            value={prospectSearch?.jobtitle}
                                            onChange={(e) => setProspectSearch({ ...prospectSearch, jobtitle: e?.target?.value })}
                                        />
                                    </div>
                                </div>}

                            <div className="de_hr">
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={handleOrgCancel} >Cancel</Button>
                                    <Button className='de_back' onClick={() => { setOpenOrgAdvanceSearch(false); setOpenAdvanceSearch(true) }}>Back</Button>
                                    <Button className='de_next' onClick={handleSearchRecords}>Search</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>

            <Modal
                open={openTableModal}
                // onClose={() => { setOpenOrgAdvanceSearch(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="advance-search-modal">
                    <Box sx={style2} style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <h3 className='adv-search-modal red-search adv-table-head'>Advanced Search</h3>
                        <div className="de_pad-org-adv-search">
                            <TableContainer component={Paper} className="ai-leads-table-main-adv">
                                <Table aria-label="collapsible table" className="ai-leads-table">
                                    <TableHead>
                                        {
                                            selectedValue === "Organization" ?
                                                <TableRow className="table-row-ai-leads">
                                                    <TableCell className="score-row-table">Organization Name</TableCell>
                                                    <TableCell className="score-row-table">Website Url</TableCell>
                                                    <TableCell className="score-row-table">City</TableCell>
                                                    <TableCell className="score-row-table">State</TableCell>
                                                    <TableCell className="score-row-table">Country</TableCell>
                                                    <TableCell className="score-row-table"></TableCell>
                                                </TableRow> :
                                                <TableRow className="table-row-ai-leads">
                                                    <TableCell className="score-row-table">First Name</TableCell>
                                                    <TableCell className="score-row-table">Last Name</TableCell>
                                                    <TableCell className="score-row-table">Email</TableCell>
                                                    <TableCell className="score-row-table">Job Title</TableCell>
                                                    <TableCell className="score-row-table">Organization</TableCell>
                                                    <TableCell className="score-row-table">Linkedin Url</TableCell>
                                                    <TableCell className="score-row-table"></TableCell>
                                                </TableRow>
                                        }

                                    </TableHead>
                                    <TableBody>
                                        {
                                            tableData?.map((item, index) => (
                                                <>
                                                    {
                                                        selectedValue === "Organization" ?
                                                            <TableRow>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.name}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.website_url}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.city}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.state}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.country}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className='de_btn_cont'>
                                                                        <Button className='de_next'>Import</Button>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow> :
                                                            <TableRow>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.first_name ? item?.first_name : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.last_name ? item?.last_name : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.email ? item?.email : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.primary_job_title ? item?.primary_job_title : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.primary_organization ? item?.primary_organization : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className="enrich-table-content">
                                                                        <p>{item?.linkedin_url ? item?.linkedin_url : "-"}</p>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                                                    <div className='de_btn_cont'>
                                                                        <Button className='de_next'>Import</Button>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>}
                                                </>
                                            ))
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="de_hr">
                                <div className='de_btn_cont'>
                                    <Button className='de_back' onClick={() => { setOpenOrgAdvanceSearch(true); setOpenTableModal(false) }}>Back</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    );
}
