import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AI_LEADS, COMPANY_PROFILE_SCREEN } from "../../Utils/Constants";
import { useLocation } from "react-router-dom";
export default function StyledMenuInput({ handleMenuSalesforce, anchorEl, handleAnchoElement, open, handleMenuDownloadExcel, handleToPDF, handleMenuArchiveRecord, handleMenuUnArchiveRecord, handleMenuTrackRecord, handleMenuRequestUpdateRecord, disableRequestUpdate, handleMenuShareRecord, showSalesForce }) {

  const location = useLocation();
  const data = location.state?.data;
  const prospectsArchiveId = location?.state?.dataProspects?.archive_id;
  const archiveId = data?.archive_id;
  
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  return (
    <>
      <div className="Leades-filter-inner-container">
        {window?.location?.pathname === (AI_LEADS || 'aiLeads') ?
          <StyledMenu
            id="action-menu"
            MenuListProps={{
              "aria-labelledby": "action-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleAnchoElement}
          >
            <MenuItem
              className="AI-Leads-button-drop-down-up"
              onClick={handleMenuDownloadExcel}
              disableRipple
            >
              <p className="push-to-slaesforce-in-button">Download to Excel</p>
            </MenuItem>
            {
              showSalesForce==="show" ?
              <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuSalesforce}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem>: showSalesForce==="disable" ?<MenuItem
                  className="AI-Leads-button-drop-down-up"
                  style={{ opacity: "0.7" }}
                  disableRipple
                >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem> : null
            }
            <MenuItem
              className="AI-Leads-button-drop-down-up"
              onClick={handleMenuArchiveRecord}
              disableRipple
            >
              <p className="push-to-slaesforce-in-button">Archive</p>
            </MenuItem>
            {/* <MenuItem
              className="AI-Leads-button-drop-down-up"
              onClick={handleMenuTrackRecord}
              disableRipple
            >
              <p className="push-to-slaesforce-in-button">Track</p>
            </MenuItem> */}
            {/* {
              disableRequestUpdate === true ? <MenuItem
                className="AI-Leads-button-drop-down-up"
                style={{ opacity: "0.7" }}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Request Update</p>
              </MenuItem> : <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuRequestUpdateRecord}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Request Update</p>
              </MenuItem>
            } */}
          </StyledMenu> :
          window?.location?.pathname.includes('companyprofilescreen') ?
            <StyledMenu
              id="action-menu"
              MenuListProps={{
                "aria-labelledby": "action-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleAnchoElement}>
              {/* <MenuItem
                className="AI-Leads-button-drop-down"
                onClick={handleMenuDownloadExcel}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Download to Excel</p>
              </MenuItem> */}
              <div className="seprator-line-forcompany"></div>
              <MenuItem
                className="AI-Leads-button-drop-down"
                onClick={handleToPDF}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button" >Download PDF</p>
              </MenuItem>
              <div className="seprator-line-forcompany"></div>
              {
              showSalesForce==="show" ?
              <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuSalesforce}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem>: showSalesForce==="disable" ?<MenuItem
                  className="AI-Leads-button-drop-down-up"
                  style={{ opacity: "0.7" }}
                  disableRipple
                >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem> : null
            }
              <div className="seprator-line-forcompany"></div>
              {archiveId  ? <MenuItem
                className="AI-Leads-button-drop-down-disabled"
                // onClick={handleMenuArchiveRecord}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Archive</p>
              </MenuItem>
              : 
              <MenuItem
                className="AI-Leads-button-drop-down"
                onClick={handleMenuArchiveRecord}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Archive</p>
              </MenuItem>}
              
              <div className="seprator-line-forcompany"></div>
              {/* <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuTrackRecord}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Track</p>
              </MenuItem> */}
              <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuShareRecord}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Share</p>
              </MenuItem>
              {/* {
                disableRequestUpdate === true ? <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  style={{ opacity: "0.7" }}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Request Update</p>
                </MenuItem> : <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  onClick={handleMenuRequestUpdateRecord}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Request Update</p>
                </MenuItem>
              } */}
            </StyledMenu> :
            <StyledMenu
              id="action-menu"
              MenuListProps={{
                "aria-labelledby": "action-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleAnchoElement}>
              <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuDownloadExcel}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button">Download to Excel</p>
              </MenuItem>
              {
              showSalesForce==="show" ?
              <MenuItem
                className="AI-Leads-button-drop-down-up"
                onClick={handleMenuSalesforce}
                disableRipple
              >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem>: showSalesForce==="disable" ?<MenuItem
                  className="AI-Leads-button-drop-down-up"
                  style={{ opacity: "0.7" }}
                  disableRipple
                >
                <p className="push-to-slaesforce-in-button"> Push to Salesforce </p>
              </MenuItem> : null
            }
              {
                window?.location?.pathname == "/Archived" ?
                  <MenuItem
                    className="AI-Leads-button-drop-down-up"
                    onClick={handleMenuUnArchiveRecord}
                    disableRipple
                  >
                    <p className="push-to-slaesforce-in-button">Un-Archive</p>
                  </MenuItem> : 
                  prospectsArchiveId ? 
                  <MenuItem
                  className="AI-Leads-button-drop-down-up-disabled"
                  // onClick={handleMenuArchiveRecord}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Archive</p>
                </MenuItem>
                  
                  :
                  <MenuItem
                    className="AI-Leads-button-drop-down-up"
                    onClick={handleMenuArchiveRecord}
                    disableRipple
                  >
                    <p className="push-to-slaesforce-in-button">Archive</p>
                  </MenuItem>
              }
              {/* {
                window?.location?.pathname !== "tracking" && <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  onClick={handleMenuTrackRecord}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Track</p>
                </MenuItem>
              } */}
              {
                window?.location?.pathname.includes("Profile") ? <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  onClick={handleMenuShareRecord}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Share</p>
                </MenuItem> : null
              }
              {/* {
                disableRequestUpdate === true ? <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  style={{ opacity: "0.7" }}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Request Update</p>
                </MenuItem> : <MenuItem
                  className="AI-Leads-button-drop-down-up"
                  onClick={handleMenuRequestUpdateRecord}
                  disableRipple
                >
                  <p className="push-to-slaesforce-in-button">Request Update</p>
                </MenuItem>
              } */}

            </StyledMenu>
        }
      </div>
    </>
  );
}