import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import FormControlProfile from '../../FormControlProfile/Index';
import { APIUrlThree, APIUrlToken, GetUserId } from '../../../Utils/Utils';
import axios from 'axios';
import DataEnrichOrgResult from './DataEnrichOrgResult';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
const DataEnrichOrgModal = ({ modalOpen, handleClose, setFile, file, setOrgUploadModal, setIsOrgModalOpen, filepath, getEnrichList, authorizedPerson, setAuthorizedPerson, setDisclaimerOne, setDisclaimerTwo }) => {
  const [loading, setLoading] = useState(false);
  const userId = GetUserId();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
  };
  const [enrichList, setEnrichList] = useState("");
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [resultTableData, setResultTableData] = useState(false);
  const headersList = file?.column_names || [];
  // Track selected values for FormControlProfile dropdowns
  const [selectedValues, setSelectedValues] = useState({
    organization: "",
    website: "",
    linkedInURL: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: ""
  });
  
  const requestId = file?.request_id || [];
  const validate = () => {
    if (!selectedValues.organization) {
      toast.error("Please select Organization");
      return false;
    } else if (!selectedValues.website && !selectedValues.linkedInURL) {
      toast.error("Please select one of the below two options");
      return false;
    } else if (!enrichList || enrichList.trim() === "") {
      toast.error("Please enter enrich list");
      return false;
    }
    return true;
  };
  const enrichProspect = () => {
    if (!validate()) return;
    setLoading(true);
    const data = {
      organization: selectedValues.organization,
      website: selectedValues.website,
      linkedin: selectedValues.linkedInURL,
      street: selectedValues.streetAddress,
      city: selectedValues.city,
      state: selectedValues.state,
      zip_code: selectedValues.zipCode,
      status: "true",
      country: "",
      // user_filename: enrichList,
      // filename: file.filename+".csv",
      // filename: file.filename?.split("uploads/")?.[1],
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/data-enrichment-csv-to-db-organization/?user_id=${userId}&category=organization&user_filename=${enrichList}&filename=${file.filename}&filepath=${filepath}&enrich_requested_by=${authorizedPerson}`,
      // url: `${APIUrlThree()}/v1/data-enrichment-mapping-organization?request_id=${requestId}`,
      data: data,
    };
    axios(option)
      .then((e) => {
        setResultTableData(e?.data?.data)
        //  setResultModalOpen(true);
        setIsOrgModalOpen(false);
        enrichProcessOrg();
        setDisclaimerTwo(false);
        setSelectedValues({
          organization: "",
          website: "",
          linkedInURL: "",
          streetAddress: "",
          city: "",
          state: "",
          zipCode: ""
        });
        setAuthorizedPerson("")
        setEnrichList("")
      })
      .catch((err) => {
        console.error('Error enriching prospect', err);
        setLoading(false);
      });
  };
  const enrichProcessOrg = () => {
    const data = {
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/data-enrichment-process-organization/?filename=${file.filename}`,
      data: data,
    };

    axios(option)
      .then((res) => {
        toast.success("Data Enrich process completed successfully");
        setLoading(false);
        getEnrichList()
      })
      .catch((err) => {
        console.error('Error processing organization', err);
        setLoading(false);
      });
  };

  const handleCloseField = () => {
    setOrgUploadModal(false);
    handleClose(false);
    setEnrichList("");
    setSelectedValues({
      organization: "",
      website: "",
      linkedInURL: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: ""
    });
    setAuthorizedPerson("")
    setDisclaimerOne("")
    setDisclaimerTwo("")
  };
  const handleBack = () => {
    handleClose(false);
    setOrgUploadModal(true);
    // setEnrichList("");
  };
  const handleProfileChange = (key, value) => {
    setSelectedValues({ ...selectedValues, [key]: value });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style}>
            <div className='de_overflow_cont'>
              <div className="de_pad">
                <h3>Field Mapping</h3>
                <p className='de_head'>Confirm your data is mapping correctly</p>
                <div className="de_input_cont">
                  <label htmlFor="">Organization <span>*</span></label>
                  <FormControlProfile
                    value={selectedValues.organization}
                    handleChange={(e) => handleProfileChange("organization", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
              </div>
              <hr className='' />
              <div className="de_hr-org">
                <p className='de_red'>*Required 1 of these 2 fields is required</p>
                <div className="de_input_cont">
                  <label htmlFor="">Website</label>
                  <FormControlProfile
                    value={selectedValues.website}
                    handleChange={(e) => handleProfileChange("website", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                <div className="de_input_cont">
                  <label htmlFor="">LinkedIn URL</label>
                  <FormControlProfile
                    value={selectedValues.linkedInURL}
                    handleChange={(e) => handleProfileChange("linkedInURL", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
              </div>
              <hr className='' />
              <div className="de_hr-org">
                <div className="de_input_cont">
                  <label htmlFor="">Street Address</label>
                  <FormControlProfile
                    value={selectedValues.streetAddress}
                    handleChange={(e) => handleProfileChange("streetAddress", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                <div className="de_input_cont">
                  <label htmlFor="">City</label>
                  <FormControlProfile
                    value={selectedValues.city}
                    handleChange={(e) => handleProfileChange("city", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                <div className="de_input_cont">
                  <label htmlFor="">State</label>
                  <FormControlProfile
                    value={selectedValues.state}
                    handleChange={(e) => handleProfileChange("state", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                <div className="de_input_cont">
                  <label htmlFor="">Zip Code</label>
                  <FormControlProfile
                    value={selectedValues.zipCode}
                    handleChange={(e) => handleProfileChange("zipCode", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
              </div>
              <hr className='' />
              <div className="de_hr-org">
                <div className="de_input_cont">
                  <label htmlFor="">Name your Enrich List (Required) <span>*</span></label>
                  <input
                    type="text"
                    placeholder='Name'
                    value={enrichList}
                    onChange={(e) => setEnrichList(e?.target?.value)}
                  />
                </div>
                <div className='de_btn_cont'>
                  <Button className='de_cancel' onClick={handleCloseField}>Cancel</Button>
                  <Button className='de_back' onClick={handleBack}>Back</Button>
                  <Button className='de_next' onClick={() => enrichProspect()}>Submit</Button>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
      {resultModalOpen ? <DataEnrichOrgResult modalOpen={resultModalOpen} handleClose={() => setResultModalOpen(false)} resultTableData={resultTableData} requestId={requestId} enrichList={enrichList} file={file} setIsOrgModalOpen={setIsOrgModalOpen} setEnrichList={setEnrichList} setOrgUploadModal={setOrgUploadModal} setSelectedValues={setSelectedValues} /> : null}
    </>
  );
};
export default DataEnrichOrgModal;