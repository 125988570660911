import React, { useState } from 'react';
import "../../Pages/DataEnrich/DataEnrich.css";
import uploadcv from "../../Assets/uploadcv.png";
import uploadcvred from "../../Assets/uploadcvred.png";
import downloadcv from "../../Assets/downloadcv.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import video from "../../../src/Assets/video.svg"
import videodash from "../../../src/Assets/play-button.png"
import { COMPETITIVE_INTEL_VIDEO, DATA_ENRICH_VIDEO, FAVORITES_VIDEO, PERSONALIZED_DASHBOARD_VIDEO } from "../../Utils/Constants";
import TrainingModal from '../Training/TrainingModal';
const DataEnrichCard = ({ setUploadModal, setOrgUploadModal }) => {
  const [isHoveredOrg, setIsHoveredOrg] = useState(false);
  const [isHoveredProspect, setIsHoveredProspect] = useState(false);
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item == 1) {
      setVideoLink(DATA_ENRICH_VIDEO)
    } else if (item == 2) {
      setVideoLink(FAVORITES_VIDEO)
    }
  }
  return (
    <div>
      <div className="two-cards-dataenrich row">
        <div className="outer-cont-box col-6">
          <div className="inner-cont-box">
            <div className="enrich-bg-card">
              <div className="shapes-enrich">
                <p className="round-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
                <p className="square-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
              </div>
              <div className="shapes-enrich">
                <p className="round-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
                <p className="square-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
              </div>
            </div>
            <div className="inner-card-table-row">
              <Table>
                <TableBody className="inner-box-bodytag-enrich row">
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="inner-box-row-no">89</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">Targaryen Restoration</p>
                    <p>Palo Alto, California</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">251-500</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">Information...+1</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p>31</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="add-icon-inner-enrich">
                      <AddIcon />
                    </p>
                  </TableCell>
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <p className="org-head-enrich">Enrich Organizational List</p>
            <p className="org-content-enrich">
              Upload an organization, and the JOI team will efficiently identify employees within that organization and uncover potential pathways to establish meaningful connections. Once the records are submitted, the system will search for them within the JOI system and data subscription services. If no matches are found, the records will be sent to our internal Data Quality team for manual review, search, and updates. Processing time may range from a few minutes to up to two business days, depending on the file size.
            </p>
            <div
              className="upload-cv-enrich"
              onClick={() => setOrgUploadModal(true)}
              onMouseEnter={() => setIsHoveredOrg(true)}
              onMouseLeave={() => setIsHoveredOrg(false)}
            >
              <img src={isHoveredOrg ? uploadcv : uploadcvred} alt="Upload CSV" />
              <p>Upload CSV</p>
            </div>
            <a href="/Organization Enrich_Sample Template.csv" download className="download-cv-enrich">
              <img src={downloadcv} alt="" /> Download Sample Template File
            </a>
          </div>
        </div>

        <div className="outer-cont-box col-6">
          <div className="inner-cont-box">
            <div className="enrich-bg-card">
              <div className="shapes-enrich">
                <p className="round-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
                <p className="square-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
              </div>
              <div className="shapes-enrich">
                <p className="round-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
                <p className="square-shape-enrich"></p>
                <p className="rectangle-shape-enrich"></p>
              </div>
            </div>
            <div className="inner-card-table-row">
              <Table>
                <TableBody className="inner-box-bodytag-enrich row">
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="inner-box-row-fname">DL</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">Devon Lane</p>
                    <p>Software Tester</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">99</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="">Clover Corp</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p>mike-smith-04567</p>
                  </TableCell>
                  <TableCell className="tc-cell-enrich col-2">
                    <p className="add-icon-inner-enrich">
                      <AddIcon className="add-icon-main-enrich" />
                    </p>
                  </TableCell>
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <p className="org-head-enrich">Enrich Prospect List</p>
            <p className="org-content-enrich">
            Upload a comprehensive list of your contacts and the JOI team will efficiently enrich their contact data and identify potential pathways to establish meaningful connections with them. Once the records are submitted, the system will search for them within the JOI system and data subscription services. If no matches are found, the records will be sent to our internal Data Quality team for manual review, search, and updates. Processing time may range from a few minutes to up to two business days, depending on the file size.
            </p>
            <div
              className="upload-cv-enrich"
              onClick={() => setUploadModal(true)}
              onMouseEnter={() => setIsHoveredProspect(true)}
              onMouseLeave={() => setIsHoveredProspect(false)}
            >
              <img src={isHoveredProspect ? uploadcv : uploadcvred} alt="Upload CSV" />
              <p>Upload CSV</p>
            </div>
            <a href="/Prospect Enrich_Sample Template.csv" download className="download-cv-enrich">
              <img src={downloadcv} alt="" /> Download Sample Template File
            </a>
          </div>
        </div>

        <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

          <img src={video} alt="video" className="videoIcon" />
          {videoModal && (
            <div className="bell-icon-box-video">

              <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                <div className="bell-icon-outer-video">
                  {/* <div className="bell-icon-container-inner"> */}
                  <img src={videodash} alt="logo" className="video_img_drop" />
                  {/* </div> */}
                  <div className="bellicon-text">
                    <p className="bellicon-p-search">Data Enrich</p>
                  </div>
                </div>
                <div className="bellicon-separator"></div>
              </div>
              <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(2)}>
                <div className="bell-icon-outer-video">
                  {/* <div className="bell-icon-container-inner"> */}
                  <img src={videodash} alt="logo" className="video_img_drop" />
                  {/* </div> */}
                  <div className="bellicon-text">
                    <p className="bellicon-p-search">Favorites</p>
                  </div>
                </div>
                <div className="bellicon-separator"></div>
              </div>

            </div>
          )}
        </div>
      </div>
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />

    </div>
  );
};
export default DataEnrichCard;