import React, { useEffect, useState } from "react";
import "./Style.css";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import reloardform from "../../../../Assets/reloardform.svg";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControlProfile from "../../../FormControlProfile/Index";
import { Autocomplete, InputBase, Paper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";

export default function DetailsFormProspect({
  setDecisionMakerData,
  decisionMakerData,
  setTableShowResult,
  tableShowResult,
  toggleResult,
  ResponseJobData,
  handelselectjobtitle,
  ShowAutocomplete,
  setShowAutocomplete,
  handlesearch,
  handelselectdata,
  ResponseDatanew, ShowAutocompleteprimary,
  prefilledData, isFilterRetrieve,
  setShowAutocompleteprimary,
  setResultRetrieve
}
) {  
  
  // const [inputValue, setInputValue] = useState(prefilledData?.revenue_range || "");
  // const [inputValueEmployees, setInputValueEmployees] = useState(prefilledData?.number_employees || "");
  useEffect(() => {
    const handleClick = () => {
      setShowAutocompleteprimary(false);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  useEffect(() => {
    const handleClick = () => {
      setShowAutocomplete(false);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const emtySearchdata = () => {
    setDecisionMakerData({ ...decisionMakerData, primaryOrganization: '', primaryJobTitle: '' });
  }
  const location = useLocation();
  

  const EmptyInputs = () => {
    setTableShowResult(true);
    setResultRetrieve([]);
    setDecisionMakerData({
      decisionmaker: "",
      primaryOrganization: "",
      sample: "",
      primaryJobTitle: "",
      sampleTwo: "",
      country: "",
      state: "",
      josfStatus: "",
      strengthScore: "",
      addedName: "",
      categories: "",
      seniority:"",
      revenueRange: [],
      numEmployees: [],

    })
    // setInputValue("");
    // setInputValueEmployees("")
  }
  // useEffect(() => {
  //   const data=inputValue //?.length ? inputValue?.split("-")?.map(i=>i.includes("$")? i :`$${i}`)?.join("-") :""
  //   if(location?.state?.disableFields === true ){
  //     setDecisionMakerData({ ...decisionMakerData, revenueRange: prefilledData?.revenue_range })
      
  //   }else{
  //     setDecisionMakerData({ ...decisionMakerData, revenueRange: data })
      
  //   }
  // }, [inputValue,prefilledData?.revenue_range]);
  // const handleAddOption = (event, newValue) => {
  //     if (newValue?.length) {
  //         setInputValue(newValue[newValue?.length - 1]);
  //     } else {
  //         setInputValue(""); // Reset if no value is selected
  //     }
  // };
  // useEffect(() => {
  //   const data=inputValueEmployees?.length ? inputValueEmployees :""
  //   if(location?.state?.disableFields === true ){
  //     setDecisionMakerData({ ...decisionMakerData, numEmployees: prefilledData?.number_employees})
  //   }else{
  //     setDecisionMakerData({ ...decisionMakerData, numEmployees:data })
  //   }
  // }, [inputValueEmployees,prefilledData?.number_employees]);

  // const handleAddOptionEmployees = (event, newValue) => {
  //     if (newValue?.length) {
  //         setInputValueEmployees(newValue[newValue?.length - 1]);
  //     } else {
  //         setInputValueEmployees(""); 
  //     }
  // };

  return (
    <div className="ai-profile-form">
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        variant="standard"
        className="ai-profile-form-main"
      >
        <div className="form-data-typeable">
          <div className="inner-form-data">
            <div>
              <div className="ai-prospect-details-form">
                <div className="heading-of-prospect-details">
                  <div className="prospect-text-and-questionmark">
                    <div className="details-perposal-text">
                      <p>AI Prospect details</p>
                    </div>
                    <div className="question-markimage">
                    </div>
                  </div>
                  <div className="reloardformimages">
                    {prefilledData ? "" : <img onClick={EmptyInputs} src={reloardform} alt="" />}
                  </div>
                </div>
                <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                    Primary Organization
                  </label>
                  <Paper className='full-searchbar-ai-profile'
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <InputBase
                      value={decisionMakerData?.primaryOrganization}
                      // disabled={prefilledData?.primary_organization || prefilledData}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setDecisionMakerData({ ...decisionMakerData, primaryOrganization: inputValue });
                        if (e.target.value.length >= 3) {
                          setTimeout(() => {
                            handlesearch(inputValue);
                          }, 1000);
                        }
                      }}
                      className='search-inner-textai-profile'
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'Search google maps' }}
                      autocomplete="off"
                    />
                    {prefilledData?.primary_organization ? "" : <div onClick={emtySearchdata} className="cross-icon-prospect">
                      {decisionMakerData.primaryOrganization && (
                        <CloseIcon className='showicons' />
                      )}            </div>}
                    <div className="PrimaryOrganizationoutter">
                      {ShowAutocompleteprimary && (
                        <div className='PrimaryOrganization-data'>
                          <div className={ResponseDatanew === "" || ResponseDatanew?.length > 0 ? '-aiprofile ' : "sethjgcghjiohgh"}>
                            {ResponseDatanew && ResponseDatanew.length > 0 ? (
                              ResponseDatanew?.map((item) => {
                                return (
                                  <>
                                    <div className='innerAutocomplete' onClick={() => handelselectdata(item)} key={item.id}>
                                      {item?.org_name}
                                    </div>
                                    <div className='separatorline'></div>
                                  </>
                                )
                              })
                            ) : (
                              <div className={ResponseDatanew?.length === 0 ? 'nodatafound' : "degtyrrf"}>
                                {ResponseDatanew?.primaryOrganization?.length || ResponseDatanew?.length === 0 ? "Not available" : ""}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Paper>
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Industry
                    {prefilledData ? null : <span className="mandatoryainputs">*</span>}
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.categories}
                    disabled={location?.state?.disableFields === true}

                    // disabled={prefilledData?.categories || prefilledData}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, categories: e })}
                    dataList={isFilterRetrieve?.data?.categories}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Revenue Range
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.revenueRange || []} 
                    disabled={location?.state?.disableFields === true}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, revenueRange: e })}
                    dataList={isFilterRetrieve?.data?.revenue_range || []}
                    isMultiple={true}
                  />
                  {/* <Autocomplete
                    freeSolo
                    options={isFilterRetrieve?.data?.revenue_range}
                    onChange={(event, newInputValue) => handleAddOption(event, newInputValue)}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    disabled={location?.state?.disableFields === true}
                    className="select-dropdown-leads"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        className="text_ai_auto"
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        fontSize: "14px",
                      },
                    }}
                  /> */}
                
                </div>

                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Number Of Employees
                    {/* {prefilledData ? null : <span className="mandatoryainputs">*</span>} */}
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.numEmployees || []} 
                    disabled={location?.state?.disableFields === true}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, numEmployees: e })}
                    dataList={isFilterRetrieve?.data?.num_employees || []}
                    isMultiple={true}
                  />
                  {/* <Autocomplete
                    freeSolo
                    options={isFilterRetrieve?.data?.num_employees}
                    onChange={(event, newInputValue) => handleAddOptionEmployees(event, newInputValue)}
                    inputValue={inputValueEmployees}
                    onInputChange={(event, newInputValue) => setInputValueEmployees(newInputValue)}
                    disabled={location?.state?.disableFields === true}
                    className="select-dropdown-leads"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        className="text_ai_auto"
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        fontSize: "14px",
                      },
                    }}
                  /> */}
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                  Seniority
                    {/* {prefilledData ? null : <span className="mandatoryainputs">*</span>} */}
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.seniority}
                    disabled={location?.state?.disableFields === true}

                    // disabled={prefilledData?.categories || prefilledData}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, seniority: e })}
                    dataList={isFilterRetrieve?.data?.seniority}
                  />
                </div>

                <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                    Country
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.country}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.country || prefilledData}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, country: e })}
                    dataList={isFilterRetrieve?.data?.country}
                  />
                </div>
                <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                    State
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.state}
                    disabled={location?.state?.disableFields === true}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, state: e })}
                    dataList={
                      decisionMakerData?.country === "United States"
                        ? isFilterRetrieve?.data?.state
                        : decisionMakerData?.country === "Canada"
                          ? isFilterRetrieve?.data?.ca_states
                          : decisionMakerData?.country === "Mexico"
                            ? isFilterRetrieve?.data?.mexico_states
                            : [] // Default to an empty array or handle as needed
                    }
                  />

                </div>
                <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                    Decision Maker
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.decisionmaker}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.decisionmaker || prefilledData}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, decisionmaker: e })}
                    dataList={isFilterRetrieve?.data?.decision_maker}
                  />
                </div>
                <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                  Contact Information Available in Salesforce
                  </label>
                  <FormControlProfile
                    value={decisionMakerData?.josfStatus}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.josf_status || prefilledData}
                    handleChange={(e) => setDecisionMakerData({ ...decisionMakerData, josfStatus: e })}
                    dataList={isFilterRetrieve?.data?.josf_status}
                  />
                </div>
                {/* <div className="label-form-prospect-div-head">
                  <label htmlFor="" className="label-floating">
                    Primary Job Title
                  </label>
                  <Paper className='full-searchbar-ai-profile'
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <InputBase
                      value={decisionMakerData?.primaryJobTitle}
                      // disabled={prefilledData?.primary_organization || prefilledData}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setDecisionMakerData({ ...decisionMakerData, primaryJobTitle: inputValue });
                      }}
                      className='search-inner-textai-profile'
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'Search google maps' }}
                      autocomplete="off"
                    />
                    {prefilledData?.primary_job_title ? "" : <div onClick={emtySearchdata} className="cross-icon-prospect">
                      {decisionMakerData.primaryJobTitle && (
                        <CloseIcon className='showicons' />
                      )}            </div>}
                    {ShowAutocomplete && (
                      <div className='setpositionaitable'>
                        <div className={ResponseJobData === "" || ResponseJobData?.length > 0 ? 'Autocompletedropdowna-aiprofile ' : "sethjgcghjiohgh"}>
                          {ResponseJobData && ResponseJobData.length > 0 ? (
                            ResponseJobData?.map((item) => {
                              return (
                                <>
                                  <div className='innerAutocomplete' onClick={() => handelselectjobtitle(item)} key={item.id}>
                                    {item?.primary_job_title}
                                  </div>
                                  <div className='separatorline'></div>
                                </>
                              )
                            })
                          ) : (
                            <div className={ResponseJobData?.length === 0 ? 'nodatafound' : "degtyrrf"}>
                              {decisionMakerData.primaryJobTitle?.length || ResponseJobData?.length === 0 ? "Not available" : ""}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Paper>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            {
              location?.state?.disableFields === true ? null :
                <Stack className="stack-form-button">
                  <Button
                    variant="contained"
                    className="Show-result-button-form"
                    style={{ textTransform: "none" }}
                    onClick={() => toggleResult("show")}
                  >
                    {"Show Result"}
                  </Button>
                </Stack>
            }
            {/* {prefilledData?.id && location?.state?.disableFields===true? null : tableShowResult ?
              <Stack className="stack-form-button">
                <Button
                  variant="contained"
                  className="Show-result-button-form"
                  style={{ textTransform: "none" }}
                  onClick={() => setTableShowResult(false)}
                >
                  {"Hide result"}
                </Button>
              </Stack> :
              <Stack className="stack-form-button">
                <Button
                  variant="contained"
                  className="Show-result-button-form"
                  style={{ textTransform: "none" }}
                  onClick={toggleResult}
                >
                  {"Show Result"}
                </Button>
              </Stack>
            } */}
          </div>
        </div>
      </FormControl>
    </div>
  );
}