import { Box, Button, Modal } from '@mui/material';
import React, { useRef, useState } from 'react';
import upload from "../.././../Assets/upload.svg";
import { APIUrlThree, APIUrlToken, GetUserId } from '../../../Utils/Utils';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import DataEnrichOrgModal from './DataEnrichOrgModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DataEnrichOrgUploadModal = ({ modalOpen, handleClose, setOrgUploadModal, getEnrichList }) => {
    const [loading, setLoading] = React.useState(false);
    const [filepath, setFilepath] = useState();
    const [disclaimerOne, setDisclaimerOne] = useState(false);
    const [disclaimerTwo, setDisclaimerTwo] = useState(false);
    const [authorizedPerson, setAuthorizedPerson] = useState('');

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };
    const [file, setFile] = React.useState({
        filename: "",
        column_names: "",
        request_id: "",
    });
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };
    const handleAreaClick = () => {
        inputRef.current.click();
    };
    const validateUpload = () => {
        if (!file?.name) {
            toast.error("Please select a CSV file");
            return false;
        }
        if (disclaimerTwo && (!authorizedPerson || authorizedPerson.trim() === "")) {
            toast.error("Please Enter Authorized Person Name");
            return false;
        }
        return true;
    };
    const validateUploadDisable = () => {
        if (!disclaimerOne || !disclaimerTwo) {
            toast.error("Please Select Agreement Before Proceeding");
            return false;
        }
        return true;
    };
    const handleUploadCsv = () => {
        if (!validateUpload()) return;
        setLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/enrichment-upload-csv/?category=organization&user_id=${Number(GetUserId())}`,
            // url: `${APIUrlThree()}/v1/data_enrich_upload?user_id=${Number(GetUserId())}`,
            data: formData,
        };
        axios(option)
            .then((e) => {
                if (e.status === 200) {
                    const { filename, column_names, request_id } = e?.data;
                    setFile({
                        filename: filename,
                        column_names: column_names,
                        request_id: request_id,
                    });
                    setFilepath(e?.data?.file_path)
                    setIsOrgModalOpen(true);
                }
                setLoading(false)
                handleClose(false);
                // toast.success(e?.data?.message)
            })
            .catch((err) => {
                console.error(err);
                setLoading(false)
            });
    };
    const handleOrgCancel = () => {
        setOrgUploadModal(false);
        setFile({});
        setDisclaimerOne("");
        setDisclaimerTwo("");
        setAuthorizedPerson("")
    }
    const handleCheckbox = (checkboxType) => {
        if (checkboxType === "one") {
            setDisclaimerOne(true);
            setDisclaimerTwo(false);
        } else if (checkboxType === "two") {
            setDisclaimerOne(false);
            setDisclaimerTwo(true);
        }
    };
    const handleUploadCsvDisable = () => {
        if (!validateUploadDisable()) return;
    }
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="vrufyerv">
                    <Box sx={style}>
                        <div className='de_overflow_cont'>
                            <div className="upload-wrapper de_wrapper">
                                <h3>Upload CSV to Enrich Your List</h3>
                                <p className='de_drag_content'>Drag and drop a .CSV file here or browse for a file to upload. Only <b>one .CSV</b> file is allowed at a time.</p>
                                <div
                                    className={`de_upload-area ${dragActive ? "de_active" : "de_inactive"}`}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={handleAreaClick}
                                >
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileChange}
                                        className="de_fileInput"
                                    />
                                    {file?.name ? (
                                        <p className='de_file_name'>{file.name}</p>
                                    ) : (
                                        <p className='de_file_name'><img src={upload} alt="upload" />Drag and Drop</p>
                                    )}
                                </div>
                                <div className='checkboxes-optns'>

                                    <div className='inputs-plus-box-dataenrich'>
                                        <input
                                            type="checkbox"
                                            checked={disclaimerOne}
                                            onChange={() => handleCheckbox("one")}
                                        />
                                        <p>I am uploading for myself.</p>
                                    </div>
                                    <div className='inputs-plus-box-dataenrich'>
                                        <input
                                            type="checkbox"
                                            checked={disclaimerTwo}
                                            onChange={() => handleCheckbox("two")}
                                        />
                                        <p>I am uploading on behalf of someone else.</p>
                                    </div>
                                    {disclaimerTwo ? <div className='person-auth'>
                                        <label htmlFor="" className='auth-person-lbl'>Person Who Authorized Upload:</label>
                                        <input
                                            value={authorizedPerson}
                                            type="text"
                                            placeholder="PERSON WHO AUTHORIZED"
                                            onChange={(e) => setAuthorizedPerson(e?.target?.value)}
                                        /></div> : null }
                                    
                                    {disclaimerOne || disclaimerTwo ? (
                                        disclaimerOne ? (
                                            <p className='disclaimer-defiend'>
                                                By checking this box and submitting the Organization/Prospect
                                                information to the system, I confirm that JobsOhio is authorized to
                                                use the information. I have verified that the information is
                                                suitable for upload and sharing, and I acknowledge it will be made
                                                available to all Internal JOI users.
                                            </p>
                                        ) : (
                                            <p className='disclaimer-defiend'>
                                                By checking this box and submitting the Organization/Prospect
                                                information to the system, I confirm that I have approved JobsOhio to use the information and verified that it
                                                is appropriate for sharing. I’m authorized to upload it, and I
                                                acknowledge it will be made available to all Internal JOI users.
                                            </p>
                                        )
                                    ) : null}
                                </div>
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={handleOrgCancel}>Cancel</Button>
                                    {/* <Button className="de_next" onClick={handleUploadCsv}>Enrich My List</Button>
                                    <Button className="de_next-disabled" onClick={handleUploadCsvDisable}>Enrich My List</Button> */}
                                    {disclaimerOne || disclaimerTwo ? <Button className="de_next" onClick={handleUploadCsv}>Enrich My List</Button> :
                                        <Button className="de_next-disabled" onClick={handleUploadCsvDisable}>Enrich My List</Button>}
                                    {/* <Button className={disclaimerOne || disclaimerTwo ? "de_next" : "de_next-disabled"} onClick={handleUploadCsv}>Enrich My List</Button> */}
                                </div>
                            </div>
                        </div>
                    </Box>
                </div >
            </Modal >
            <DataEnrichOrgModal
                setOrgUploadModal={setOrgUploadModal}
                setAuthorizedPerson={setAuthorizedPerson}
                setDisclaimerOne={setDisclaimerOne}
                setDisclaimerTwo={setDisclaimerTwo}
                authorizedPerson={authorizedPerson}
                setFile={setFile}
                file={file}
                modalOpen={isOrgModalOpen}
                handleClose={() => setIsOrgModalOpen(false)}
                setIsOrgModalOpen={setIsOrgModalOpen}
                filepath={filepath}
                getEnrichList={getEnrichList}
            />
        </>
    );
};
export default DataEnrichOrgUploadModal;