import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
const CrieMenuIndirectSeparate = ({ isMenuOpen, alldata, handleCheckboxChange, handleSearch, isSearch }) => {
    // Separate data by node_type
    const persons = React.useMemo(() => alldata?.filter(item => item.node_type === "person") || [], [alldata]);
    const organizations = React.useMemo(() => alldata?.filter(item => item.node_type === "organization") || [], [alldata]);
    return (
        <>
            {isMenuOpen && (
                <div className="crie-filter-inner-container-drop">
                    <div>
                        {isSearch && (
                            <div className="crie_input_cont">
                                <input
                                    type="text"
                                    placeholder="Type to search"
                                    className="crie_input"
                                    onChange={(e) => handleSearch(e?.target?.value)}
                                />
                            </div>
                        )}
                        {/* Persons Section */}
                        {persons.length > 0 && (
                            <div>
                                <h3 className='useralldata-indirect'>Prospects</h3>
                                {persons.map(item => (
                                    <div
                                        className="location_filter_leads_crie"
                                        onClick={() => handleCheckboxChange(item)}
                                        key={item.joi_id}
                                    >
                                        <div className="AI-leader-checkbox-and-data-list">
                                            <div className="checkbox-select-data">
                                                <MenuItem className="menuitems">{item?.name}</MenuItem>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* Organizations Section */}
                        {organizations.length > 0 && (
                            <div>
                                <h3 className='useralldata-indirect'>Organizations</h3>
                                {organizations.map(item => (
                                    <div
                                        className="location_filter_leads_crie"
                                        onClick={() => handleCheckboxChange(item)}
                                        key={item.joi_id}
                                    >
                                        <div className="AI-leader-checkbox-and-data-list">
                                            <div className="checkbox-select-data">
                                                <MenuItem className="menuitems">{item?.name}</MenuItem>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default CrieMenuIndirectSeparate;