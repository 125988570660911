import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import FormControlProfile from '../../FormControlProfile/Index';
import { APIUrlThree, APIUrlToken, GetUserId } from '../../../Utils/Utils';
import axios from 'axios';
import DataEnrichResultModal from './DataEnrichResult';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
const DataEnrichProspectsModal = ({ modalOpen, handleClose, setFile, file, setUploadModal, setIsProspectsModalOpen, filepath, getEnrichList, authorizedPerson, setAuthorizedPerson ,setDisclaimerTwo,setDisclaimerOne}) => {
  const [loading, setLoading] = React.useState(false);
  const userId = GetUserId();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
  };
  const [enrichList, setEnrichList] = useState("");
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [resultTableData, setResultTableData] = useState(false);
  const headersList = file?.column_names || [];
  const requestId = file?.request_id || [];
  // Track selected values for FormControlProfile dropdowns
  const [selectedValues, setSelectedValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    jobTitle: "",
    linkedIn: ""
  });
  const handleProfileChange = (key, value) => {
    setSelectedValues({ ...selectedValues, [key]: value });
  };
  const validate = () => {
    if (!selectedValues.firstName) {
      toast.error("Please select First Name");
      return false;
    } else if (!selectedValues.lastName) {
      toast.error("Please select Last Name");
      return false;
    } else if (!selectedValues.email) {
      toast.error("Please select email");
      return false;
    } else if (!selectedValues.jobTitle && !selectedValues.organization && !selectedValues.linkedIn) {
      toast.error("Please select one of the below three options");
      return false;
    } else if (!enrichList || enrichList?.trim() === "") {
      toast.error("Please enter name of your enrich list");
      return false;
    }
    return true;
  };
  const enrichProspect = () => {
    if (!validate()) return;
    setLoading(true);

    const data = {
      firstname: selectedValues.firstName,
      lastname: selectedValues.lastName,
      email: selectedValues.email,
      job_title: selectedValues.jobTitle,
      organization: selectedValues.organization,
      linkedin_url: selectedValues.linkedIn,
      status: "true",
    };

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/data-enrichment-csv-to-db-prospects/?user_id=${userId}&category=prospects&user_filename=${enrichList}&filename=${file.filename}&filepath=${filepath}&enrich_requested_by=${authorizedPerson}`,
      data: data,
    };

    axios(option)
      .then((e) => {
        setResultTableData(e?.data?.data);
        setIsProspectsModalOpen(false);
        enrichProcessProspects();
        setAuthorizedPerson("")
        setDisclaimerOne("");
        setDisclaimerTwo("");
      })
      .catch((err) => {
        console.error("Error enriching prospect", err);
        setLoading(false);
      });
  };

  const enrichProcessProspects = () => {
    const data = {
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/data-enrichment-process-prospects/?filename=${file.filename}`,
      data: data,
    };

    axios(option)
      .then((res) => {
        toast.success("Data Enrich process completed successfully");
        getEnrichList()
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error processing organization', err);
        setLoading(false);
      });
  };

  const handleCloseField = () => {
    setUploadModal(false);
    handleClose(false);
    setEnrichList("");
    // Reset selected values
    setSelectedValues({
      firstName: "",
      lastName: "",
      email: "",
      organization: "",
      jobTitle: "",
      linkedIn: ""
    });
    setAuthorizedPerson("")
    setDisclaimerOne("");
    setDisclaimerTwo("");
  };
  const handleBack = () => {
    handleClose(false);
    setUploadModal(true);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style} style={{ overflowY: "auto", height: "90vh" }}>
            <div className="de_pad">
              <h3>Field Mapping</h3>
              <p className='de_head'>Confirm your data is mapping correctly</p>
              {/* First Name Field */}
              <div className="label-form-prospect-div-head">
                <label htmlFor="" className="label-floating">
                  First Name<span className="dt-en-red">*</span>
                </label>
                <FormControlProfile
                  value={selectedValues.firstName}
                  handleChange={(e) => handleProfileChange("firstName", e)}
                  dataList={headersList}
                  selectedValues={Object.values(selectedValues)}
                />
              </div>
              {/* Last Name Field */}
              <div className="label-form-prospect-div-head">
                <label htmlFor="" className="label-floating">
                  Last Name<span className="dt-en-red">*</span>
                </label>
                <FormControlProfile
                  value={selectedValues.lastName}
                  handleChange={(e) => handleProfileChange("lastName", e)}
                  dataList={headersList}
                  selectedValues={Object.values(selectedValues)}
                />
              </div>
              {/* Email Field */}
              <div className="label-form-prospect-div-head">
                <label htmlFor="" className="label-floating">
                  Email<span className="dt-en-red">*</span>
                </label>
                <FormControlProfile
                  value={selectedValues.email}
                  handleChange={(e) => handleProfileChange("email", e)}
                  dataList={headersList}
                  selectedValues={Object.values(selectedValues)}
                />
              </div>
              <hr className='' />
              <div className="de_hr">
                <p className='de_red'>*Required 1 of these 3 fields is required </p>
                {/* Organization Field */}
                <div className="label-form-prospect-enrich">
                  <label htmlFor="" className="label-floating">
                    Organization
                  </label>
                  <FormControlProfile
                    value={selectedValues.organization}
                    handleChange={(e) => handleProfileChange("organization", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                {/* Job Title Field */}
                <div className="label-form-prospect-enrich">
                  <label htmlFor="" className="label-floating">
                    Job Title
                  </label>
                  <FormControlProfile
                    value={selectedValues.jobTitle}
                    handleChange={(e) => handleProfileChange("jobTitle", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
                {/* LinkedIn URL Field */}
                <div className="label-form-prospect-enrich">
                  <label htmlFor="" className="label-floating">
                    LinkedIn URL
                  </label>
                  <FormControlProfile
                    value={selectedValues.linkedIn}
                    handleChange={(e) => handleProfileChange("linkedIn", e)}
                    dataList={headersList}
                    selectedValues={Object.values(selectedValues)}
                  />
                </div>
              </div>
              <hr className='' />
              <div className="de_hr">
                <div className="de_input_cont">
                  <label htmlFor="">Name your Enrich List (Required) <span>*</span></label>
                  <input
                    type="text"
                    placeholder='Name'
                    value={enrichList}
                    onChange={(e) => setEnrichList(e?.target?.value)}
                  />
                </div>
                <div className='de_btn_cont'>
                  <Button className='de_cancel' onClick={handleCloseField}>Cancel</Button>
                  <Button className='de_back' onClick={handleBack}>Back</Button>
                  <Button className='de_next' onClick={() => enrichProspect()}>Submit</Button>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
      {/* Result Modal */}
      <DataEnrichResultModal
        modalOpen={resultModalOpen}
        handleClose={() => setResultModalOpen(false)}
        resultTableData={resultTableData}
        requestId={requestId}
        enrichList={enrichList}
        file={file}
        setIsProspectsModalOpen={setIsProspectsModalOpen}
        setResultModalOpen={setResultModalOpen}
        setUploadModal={setUploadModal}
        setEnrichList={setEnrichList}
        setSelectedValues={setSelectedValues}
      />
    </>
  );
};
export default DataEnrichProspectsModal;