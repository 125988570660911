import React, { useEffect, useRef, useState } from 'react'
import "./Crie.css"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import CrieMenu from './CrieMenu';
import phone from "../../../../Assets/phone.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkedINImage from "../../../../Assets/linkedin.svg";
import massageicon from "../../../../Assets/FrameDiff.svg";
import company from "../../../../Assets/people-logo.png";
import education from "../../../../Assets/cap-logo.png";
import experience from "../../../../Assets/jobcompany.png";
import ReplayIcon from "@mui/icons-material/Replay";
import linkedin from "../../../../Assets/linkedin.svg";
import visitLinkImage from "../../../../Assets/visit-link.svg";
import { APIUrlThree, APIUrlToken } from '../../../../Utils/Utils';
import axios from 'axios';
import { CloseOutlined } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useLocation } from 'react-router-dom';
import partnerimg from "../../../../Assets/partnerimg.svg"
const CrieDirectPath = ({
    dataForInformation, crieDataInitial,
    crieData, setCrieData,
    crieDstList, crieSrcList,
    joAssociateList, setJoAssociateList,
    organizationAssociateList, setOrganizationAssociateList,
    setModalOpen, setLoading,
    handleRadioChange, selectedValue, disabledIndirect, setModalOpenRefresh }) => {
    // Jo Associate 
    const location = useLocation();
    const isComponyScreen = location?.state?.isComponyScreen;
    const [visibleItems, setVisibleItems] = useState(10);
    const itemsPerLoad = 10;
    const handleLoadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerLoad);
    };
    const [isMenuJoassociate, setIsMenuJoassociate] = React.useState(false);
    const [joassociate, setJoassociate] = React.useState([]);
    const handleChangeJoAssociate = (item) => {
        setJoassociate([item]);
        setIsMenuJoassociate(false)
    }
    const handleSearchJoAssociate = (e) => {
        if (e?.length) {
            const data = crieSrcList?.filter(item => item?.name?.toLowerCase()?.includes(e?.toLowerCase()))
            setJoAssociateList(data)
        } else {
            setJoAssociateList(crieSrcList)
        }
    }
    // Path Weight
    const allPathWeightdata = [
        {
            name: "Extra Strong 85-100",
            value: [85, 100]
        },
        {
            name: "Strong 71-84",
            value: [71, 84]
        },
        {
            name: "Slightly Strong 57-70",
            value: [57, 70]
        },
        {
            name: "Neutral 43-56",
            value: [43, 56]
        },
        {
            name: "Slightly Weak 29-42",
            value: [29, 42]
        },
        {
            name: "Weak 15-28",
            value: [15, 28]
        },
        {
            name: "Extra Weak 1-14",
            value: [1, 14]
        }
    ];

    const [isMenuPathWeight, setIsMenuPathWeight] = React.useState(false);
    const [pathWeight, setPathWeight] = React.useState([]);
    const [pathWeightList, setPathWeightList] = useState(allPathWeightdata)
    const handleChangePathWeight = (item) => {
        setPathWeight([item]);
        setIsMenuPathWeight(false)
    }
    //organization Associate
    const [isMenuOrganizationassociate, setIsMenuOrganizationassociate] = React.useState(false);
    const [organizationassociate, setOrganizationassociate] = React.useState([]);
    const handleChangeOrganizationAssociate = (item) => {
        setOrganizationassociate([item]);
        setIsMenuOrganizationassociate(false)
    }
    const handleSearchOrganizationAssociate = (e) => {
        if (e?.length) {
            const data = crieDstList?.filter(item => item?.name?.toLowerCase()?.includes(e?.toLowerCase()))
            setOrganizationAssociateList(data)
        } else {
            setOrganizationAssociateList(crieDstList)
        }
    }
    useEffect(() => {
        setJoassociate([])
        setOrganizationassociate([])
        setPathWeight([])
    }, [dataForInformation, isComponyScreen])

    const handleSearchCrie = () => {
        setIsMenuJoassociate(false)
        setIsMenuOrganizationassociate(false)
        setIsMenuPathWeight(false)
        const data = {}
        if (joassociate?.length) {
            data.src_id = joassociate;
        }

        if (organizationassociate?.length) {
            data.dest_id = organizationassociate;
        }

        if (pathWeight?.length) {
            data.strength = pathWeight;
        }
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/get_crie?src_org_id=${process.env.REACT_APP_JOI_JO_ID}&dest_org_id=${dataForInformation?.org_id}`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    let data = e?.data
                    setCrieData(data)
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }
    const handleResetFilter = () => {
        setCrieData(crieDataInitial)
        setJoassociate([])
        setOrganizationassociate([])
        setPathWeight([])
    }
    const joRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (joRef.current && !joRef.current.contains(event.target)) {
                setIsMenuJoassociate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [joRef]);
    const orgRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (orgRef.current && !orgRef.current.contains(event.target)) {
                setIsMenuOrganizationassociate(false)
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [orgRef]);
    const pathRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pathRef.current && !pathRef.current.contains(event.target)) {
                setIsMenuPathWeight(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pathRef]);

    return (
        <div>
            <div className={`crie-filter-inner-container ${disabledIndirect === "submitted" ? "crie-cont-x" : "crie-cont-2"} do_not_print`}>
                <div className='crie-inner-drop-boxes'>
                    {/* Jo Associate */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            style={{ textTransform: "none" }}
                            onClick={() => {
                                setIsMenuJoassociate(!isMenuJoassociate);
                                setJoAssociateList(crieSrcList)
                                setIsMenuPathWeight(false);
                                setIsMenuOrganizationassociate(false);
                            }}
                        >
                            JO Employee<KeyboardArrowDownIcon />
                        </div>
                        <div ref={joRef}>
                            <CrieMenu
                                isMenuOpen={isMenuJoassociate}
                                alldata={joAssociateList}
                                handleCheckboxChange={handleChangeJoAssociate}
                                checked={joassociate}
                                handleSearch={handleSearchJoAssociate}
                                isSearch={true}
                            />
                        </div>
                    </div>

                    <div className='hyphen-conn-main'>
                        <p className='line-hyphen-conn'></p>
                        <p className='hyphen-conn'>-</p>
                    </div>
                    {/* organization associate */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            onClick={() => {
                                setIsMenuOrganizationassociate(!isMenuOrganizationassociate);
                                setIsMenuPathWeight(false);
                                setIsMenuJoassociate(false);
                                setOrganizationAssociateList(crieDstList)
                            }}
                        >
                            {dataForInformation?.name} Employee<KeyboardArrowDownIcon />
                        </div>
                        <div ref={orgRef}>
                            <CrieMenu
                                isMenuOpen={isMenuOrganizationassociate}
                                alldata={organizationAssociateList}
                                handleCheckboxChange={handleChangeOrganizationAssociate}
                                checked={organizationassociate}
                                handleSearch={handleSearchOrganizationAssociate}
                                isSearch={true}
                            />
                        </div>
                    </div>
                    {/* Path weight */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            onClick={() => {
                                setIsMenuPathWeight(!isMenuPathWeight);
                                setIsMenuJoassociate(false);
                                setOrganizationAssociateList(crieDstList)
                                setJoAssociateList(crieSrcList)
                                setIsMenuOrganizationassociate(false);
                            }}
                        >
                            Path Strength<KeyboardArrowDownIcon />
                        </div>
                        <div ref={pathRef}>
                            <CrieMenu
                                isMenuOpen={isMenuPathWeight}
                                alldata={pathWeightList}
                                handleCheckboxChange={handleChangePathWeight}
                                checked={pathWeight}
                                isSearch={false}
                            />
                        </div>
                    </div>
                    <Button onClick={handleSearchCrie} className='crie-filter-inner-container-search'>
                        Search
                    </Button>
                    {/* Reset Filter */}
                    <div className='crie_outer_drop' >
                        <div className="inner-rest-button-crie" onClick={handleResetFilter}>
                            <ReplayIcon className="reset-tick-icon" />
                            Reset Filter
                        </div>
                    </div>
                </div>
                {
                    disabledIndirect === "pending" ?
                        <Button className='crie-filter-inner-container-crie' onClick={() => setModalOpen(true)}>
                            Enable Indirect Connection Paths
                        </Button> : null
                }
                {
                    disabledIndirect === "submitted" ? <div className='crie_indirect_process'>
                        <p>Indirect Paths In Process</p>
                        <Button className='crie-filter-inner-container-crie-disabled'>
                            Enable Indirect Connection Paths
                        </Button>
                    </div> : null
                }
                {
                    disabledIndirect === "processing" ?
                        <Button className='crie-filter-inner-container-crie' onClick={() => setModalOpenRefresh(true)}>
                            Refresh Indirect Paths
                        </Button> : null
                }
                {
                    disabledIndirect === "refresh" ? <div className='crie_indirect_process'>
                        <p>Indirect Paths In Process</p>
                        <Button className='crie-filter-inner-container-crie-disabled'>
                            Refresh Indirect Paths
                        </Button>
                    </div> : null
                }
            </div>
            <div className="crie-filter-inner-container cre-list-cont">
                <div className='crie-inner-drop-boxes-2'>
                    {
                        joassociate?.length ? <>
                            {
                                joassociate?.map((item) => (
                                    <div className='crie_selected_names'>{joAssociateList?.find(i => i?.value == item)?.name} <CloseOutlined onClick={() => setJoassociate([])} /></div>
                                ))
                            }
                        </> : null
                    }

                </div>
                <div className='crie-inner-drop-boxes-2'>
                    {
                        organizationassociate?.length ? <>
                            {
                                organizationassociate?.map((item) => (
                                    <div className='crie_selected_names'>{organizationAssociateList?.find(i => i?.value == item)?.name} <CloseOutlined onClick={() => setOrganizationassociate([])} /></div>
                                ))
                            }
                        </> : null
                    }

                </div>
                <div className='crie-inner-drop-boxes-2'>
                    {
                        pathWeight?.length ? <>
                            {
                                pathWeight?.map((item) => (
                                    <div className='crie_selected_names'>{pathWeightList?.find(i => i?.value == item)?.name} <CloseOutlined onClick={() => setPathWeight([])} /></div>
                                ))
                            }
                        </> : null
                    }

                </div>
            </div>
            {/* <div className='crie-cont crie_direct'>Direct Connection Paths</div> */}
            <div style={{ padding: "20px" }}>
                <div className='path-graph-crie'>
                    {/* <div className='radio-input-crie'> <div className='direct-path-crie'><input type="radio" /><p>Direct Paths</p></div> <div className='in-direct-path-crie'><input type="radio" /><p>In-Direct Paths</p></div></div> */}
                    <div className='radio-input-crie'>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className='rg-direct-paths'
                                onChange={(e) => handleRadioChange(e)}
                            >
                                <FormControlLabel className='direct-path-crie' checked={selectedValue === 'direct'} value="direct" control={<Radio />} label="Direct Paths" />
                                <FormControlLabel
                                    className={disabledIndirect == "pending" || disabledIndirect === "submitted" ? 'in-direct-path-crie' : 'direct-path-crie'}
                                    checked={selectedValue === 'indirect'}
                                    value="indirect"
                                    disabled={disabledIndirect == "pending" || disabledIndirect === "submitted"}
                                    control={<Radio />}
                                    label="Indirect Paths"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        crieData?.length ? <>
                            {
                                crieData.sort((a, b) => b.strength - a.strength)?.slice(0, visibleItems)?.map((item, index) => (
                                    <div className='crie_path_cont'>
                                        <div className='first_crie_box'>
                                            <div className='crie_info'>
                                                <div className='crie_name'>{item?.src_name}</div>
                                                <div>Jobs Ohio</div>
                                                <a className='company-profile-mention'> <EmailOutlinedIcon style={{ color: "#003057", width: "15px", height: "15px" }} /> {item?.src_email ? item?.src_email.split(",")[0] : "--"}</a>
                                                <a className='company-profile-mention'> <img src={phone} alt="" style={{ width: "13px", height: "13px" }} />{item?.src_phone ? item?.src_phone : "--"} </a>
                                                <p className='company-profile-mention-2'><span>
                                                    <img src={linkedin} alt="" style={{ width: "14px", height: "14px" }} />{item?.src_linkedin ? "Linkedin Profile" : "--"}</span>
                                                    {item?.src_linkedin ? <a href={item?.src_linkedin} target="_blank" className='crie-a-end'> <img src={visitLinkImage} alt="" style={{ width: "14px", height: "14px" }} /></a> : <a></a>} </p>
                                            </div>
                                        </div>
                                        <div className='crie_line'>
                                            <div className="crie-img">
                                                <Tooltip
                                                    title={
                                                        "Weighted Connection Strength"
                                                    }
                                                >
                                                    <div className="weight-between-nodes-crie">
                                                        {item?.strength}
                                                    </div>
                                                </Tooltip>
                                                {
                                                    item?.connection_types?.includes("Email") ? <Tooltip title={"Email Exchanged"}>
                                                        <img
                                                            src={massageicon}
                                                            alt=""
                                                            className="siderbar-modal-img"
                                                        />
                                                    </Tooltip> : null
                                                }

                                                {
                                                    item?.connection_types?.includes("LinkedIn") ?
                                                        <Tooltip
                                                            title={"LinkedIn Connection"}
                                                        >
                                                            <img
                                                                src={LinkedINImage}
                                                                alt=""
                                                                className="siderbar-modal-img"
                                                            />
                                                        </Tooltip> : null}
                                                {
                                                    item?.connection_types?.includes("Organization") ? <Tooltip
                                                        title={"Working in Same Organization"}
                                                    >
                                                        <img
                                                            src={company}
                                                            alt=""
                                                            className="siderbar-modal-img"
                                                        />
                                                    </Tooltip> : null
                                                }

                                                {
                                                    item?.connection_types?.includes("Education") ? <Tooltip
                                                        title={"Attended Same College"}
                                                    >
                                                        <img
                                                            src={education}
                                                            alt=""
                                                            className="siderbar-modal-img"
                                                        />
                                                    </Tooltip> : null
                                                }

                                                {
                                                    item?.connection_types?.includes("Experience") ? <Tooltip
                                                        title={
                                                            "Previously Worked in Same Organization"
                                                        }
                                                    >
                                                        <img
                                                            src={experience}
                                                            alt=""
                                                            className="siderbar-modal-img"
                                                        />
                                                    </Tooltip> : null
                                                }

                                                {
                                                    item?.connection_types?.includes("Meeting") ? <Tooltip
                                                        title={
                                                            "Meeting"
                                                        }
                                                    >
                                                        <CalendarMonthIcon className="siderbar-modal-img" />

                                                    </Tooltip> : null
                                                }
                                                {
                                                    item?.connection_types?.includes("Partner") ? <Tooltip
                                                        title={
                                                            "Regional Partner"
                                                        }
                                                    >
                                                        <img src={partnerimg} alt="" className="siderbar-modal-img" />

                                                    </Tooltip> : null
                                                }


                                            </div>
                                        </div>
                                        <div className='first_crie_box'>
                                            <div className='crie_info'>
                                                <div className='crie_name'>{item?.dst_name}</div>
                                                <div className='crie_name'>{dataForInformation?.name}</div>
                                                <a className='company-profile-mention'> <EmailOutlinedIcon style={{ color: "#003057", width: "15px", height: "15px" }} /> {
                                                    item?.dst_email
                                                        ? item?.dst_email
                                                        : "--"} </a>
                                                <a className='company-profile-mention'> <img src={phone} alt="" style={{ width: "13px", height: "13px" }} />{item?.dst_phone ? item?.dst_phone : "--"}</a>
                                                <p className='company-profile-mention-2'><span><img src={linkedin} alt="" style={{ width: "14px", height: "14px" }} />{item?.dst_linkedin ? "Linkedin Profile" : "--"}</span> {item?.dst_linkedin ? <a href={item?.dst_linkedin} className='crie-a-end' target='_blank'> <img src={visitLinkImage} alt="" style={{ width: "14px", height: "14px" }} /></a> : null} </p>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </> : <div className='crie_path_cont'>No Records Found !</div>

                    }
                    <div className="loadmore-pagination-section">
                        {visibleItems < crieData?.length ? <button className="btn-load-more button-loadmore-pagination crie-load-more" onClick={handleLoadMore} >Load More</button> : null}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CrieDirectPath