import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { APIUrlThree, APIUrlToken } from '../../../Utils/Utils';
import axios from 'axios';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
const DataEnrichResultModal = ({ modalOpen, handleClose, resultTableData = [], requestId, enrichList, file, setIsProspectsModalOpen, setEnrichList, setUploadModal , setSelectedValues,setDisclaimerTwo}) => {
    const [loading, setLoading] = React.useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '8px',
        p: 4,
        overflowY: "auto",
        height: "90vh"
    };
    const handleSubmit = () => {
        setLoading(true);
        const filteredData = (Array.isArray(resultTableData) ? resultTableData : []).filter(item => item.found === false);
        const data = filteredData.map(item => ({
            fname: item.first_name,
            lname: item.last_name,
            email: item.email,
            filename: file.filename,
            job_title: item.jobtitle,
            organization: item.organization,
            linkedIn_url: item.linkedIn,
            user_filename: enrichList,
            status: "true",
        }));
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "X-API-Key": APIUrlToken(),
                "Content-Type": "application/json",
            },
            url: `${APIUrlThree()}/v1/data_enrich_save_prospect?request_id=${requestId}`,
            data: data,
        };
        axios(option)
            .then((response) => {
                setLoading(false);
                handleClose(true);
                setIsProspectsModalOpen(false);
                if (response.data && response.data.message) {
                    toast.success(response.data.message);
                }
                setEnrichList("")
                setDisclaimerTwo(false)
                setSelectedValues({
                    firstName: "",
                    lastName: "",
                    email: "",
                    organization: "",
                    jobTitle: "",
                    linkedIn: ""
                  });
            })
            .catch((err) => {
                console.error('Error enriching prospect', err.response ? err.response.data : err);
                console.error('Request config:', option);
                console.error('Data sent:', data);
                setLoading(false);
            });
    };
    const handleProspectClose = () => {
        setEnrichList("");
        handleClose(true);
        setIsProspectsModalOpen(false);
        setUploadModal(false);
        setSelectedValues({
            firstName: "",
            lastName: "",
            email: "",
            organization: "",
            jobTitle: "",
            linkedIn: ""
          });
    }
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="vrufyerv">
                    <Box sx={style}>
                        <div className="de_pad">
                            <h3>Enrichment Results</h3>
                        </div>
                        <div className='records-heading-enrich'>
                            <p>{(Array.isArray(resultTableData) ? resultTableData.length : 0)} Total Records</p> |
                            <p>{(Array.isArray(resultTableData) ? resultTableData.filter(item => item?.found === false).length : 0)} New</p> |
                            <p>{(Array.isArray(resultTableData) ? resultTableData.filter(item => item?.found === true).length : 0)} Exist in JOI</p>
                        </div>
                        <div className="de_hr">
                            <TableContainer component={Paper} className="leads-profile-table-main">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Job Title</TableCell>
                                            <TableCell>Organization</TableCell>
                                            <TableCell>LinkedIn URL</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="leads-profile-tablebody">
                                        {(Array.isArray(resultTableData) ? resultTableData : []).map((item, index) => (
                                            <TableRow key={index} className={item?.found == true ? "gray_row_table" : ""}>
                                                <TableCell>{item.first_name}</TableCell>
                                                <TableCell>{item.last_name}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.jobtitle}</TableCell>
                                                <TableCell>{item.organization}</TableCell>
                                                <TableCell>{item.linkedIn}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className='de_btn_cont'>
                                <Button className='de_cancel' onClick={handleProspectClose}>Cancel</Button>
                                <Button className='de_back' onClick={handleClose}>Back</Button>
                                <Button className='de_next' onClick={handleSubmit}>Submit</Button>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    );
};
export default DataEnrichResultModal;